import React, { useState } from "react"
import 'react-h5-audio-player/lib/styles.css'
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import Icon from "../icon"
import { analyticClickEvent } from "../googleAnalytics"
import {
    StyledAudioCard,
    StyledAudioTitle,
    StyledAudioDuration,
    StyledAudioModal,
    StyledAudioModalCloseButton,
    StyledSingleAudioHeader,
    StyledSingleAudioTitle,
    StyledSingleAudioBody,
    StyledAudioItem
} from "../../styledComponents/app/audioCard"
import { ReactAudioPlayer } from "../reactPlayer"

const AudioModal = (data) => {
    const [isOpen, setIsOpen] = useState(false);
    const [opacity, setOpacity] = useState(0);
  
    function toggleModal(e) {
      setOpacity(0);
      setIsOpen(!isOpen);
    }

    function toggleModalClose(e) {
      setOpacity(0);
      setIsOpen(!isOpen);
      let action
      if(identity.user){
        action = 'UserID: ' + identity.user.id + ' Closes'
      }
      else {
        action = 'UserID: NOT LOGGED IN Closes'
      }
      analyticClickEvent(action,'Engagement', data.audio.title, '')
    }
  
    function afterOpen() {
      setTimeout(() => {
        setOpacity(1);
      }, 100);
    }
  
    function beforeClose() {
      return new Promise((resolve) => {
        setOpacity(0);
        setTimeout(resolve, 300);
      });
    }
    const audio = data.audio

    const identity = useIdentityContext()
    let action
    if(identity.user){
      action = 'UserID: ' + identity.user.id + ' Watches'
    } else {
      action = 'UserID: NOT LOGGED IN Watches'
    }
    
    return (
      <>
        {!data.audio.type ? 
          <StyledAudioItem onClick={toggleModal}>
            <StyledAudioTitle>{audio.title || data.audio.programName}</StyledAudioTitle>
              <img src={audio.image || data.audio.featuredImage} alt="" />
              {audio.duration && <StyledAudioDuration>{audio.duration} min</StyledAudioDuration>}
              <span><Icon name="playButton" /></span>
          </StyledAudioItem> 
        : 
          <StyledAudioCard onClick={toggleModal}>
            <StyledAudioTitle>{audio.title || data.audio.programName}</StyledAudioTitle>
            <img src={audio.image || data.audio.featuredImage} alt="" />
            {audio.duration && <StyledAudioDuration>{audio.duration} min</StyledAudioDuration>}
          </StyledAudioCard>
        }
        
        <StyledAudioModal
            isOpen={data.audio.type ==='single' ? !isOpen : isOpen}
            afterOpen={afterOpen}
            beforeClose={beforeClose}
            onBackgroundClick={toggleModal}
            onEscapeKeydown={toggleModal}
            opacity={opacity}
            backgroundProps={{ opacity }}
            backgroundImage={data.audio.featuredImage || audio.image}
            colourOverlay={audio.color}
            className="single-audio-modal"
        >
            <StyledSingleAudioHeader>
                <StyledAudioModalCloseButton onClick={toggleModalClose}>
                    <Icon name="cross" />
                </StyledAudioModalCloseButton>
                <div className="iconContainer">
                    <Icon name="karinagrant" />
                </div>
                <StyledSingleAudioTitle className="audio-header-title">{audio.title}</StyledSingleAudioTitle>
            </StyledSingleAudioHeader>
            <StyledSingleAudioBody>
                <ReactAudioPlayer
                    autoPlay={true}
                    audioURL={audio.audioMp3}
                    onPlay={e => analyticClickEvent(action,'Video','Play: '+ audio.title)} 
                    title={audio.title}
                />
            </StyledSingleAudioBody>
        </StyledAudioModal>
      </>
    )
  }
export default AudioModal